import React, { useEffect } from 'react';
import axios from 'axios';

export default function Ios() {
	useEffect(() => {
		axios
			.get('https://backend.toyota-toolbox.de/api/toolbox/v1/app-download')
			.then((response) => {
				if (response.data) {
					window.location.href = response.data.data;
				}
			});
	}, []);
	return <div className="centerCenter">Bitte warten ...</div>;
}
