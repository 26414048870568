import './App.scss';
import Logo from './logo.svg';
import Mockup from './mockups.png';
import { Link } from 'react-router-dom';
import QRcode from 'qrcode.react';

function Intro() {
	return (
		<div className="Intro">
			<div className="red-line"></div>
			<div className="upper">
				<div className="logo">
					<img src={Logo} alt="Logo" />
				</div>
				<a className="help" href="mailto:toolbox@toyota.de">
					Hilfe
				</a>
				<a className="content">
					<div>
						<p className="sub">Jetzt downloaden:</p>
						<h1>Die Toolbox für die Hosentasche</h1>
						<p>
							Schnell, mobil, immer mit dabei: Mit der Toolbox App haben Sie
							alle Infos rund um den Kundenservice, das Zubehör- und
							Teilegeschäft und die Kundenbindung immer in der Hosentasche.
						</p>{' '}
						<p>
							Egal ob morgens beim Kaffee, in der Pause oder im Kundengespräch:
							Mit wenigen Klicks finden Sie, was Sie suchen. Welche Aktionen
							laufen gerade? Wie lauten die Verkaufsargumente für die einzelnen
							Produkte? Worauf bekomme ich aktuell TIP-Bonifizierungen?
						</p>
						<p>
							All das gibt es einfach und bequem in der Toyota Toolbox App –
							jetzt herunterladen!
						</p>
					</div>
					<img src={Mockup} alt="Mockup" />
				</a>
			</div>
			<div className="codes">
				<div>
					<p className="head">Download für Android</p>
					<QRcode value="https://app.toyota-toolbox.de/Toolbox.apk" />

					<p>
						Oder klicken Sie <a href="/Toolbox.apk">hier.</a>
					</p>
				</div>
				<div>
					<p className="head">Download für Apple</p>
					<QRcode value="https://app.toyota-toolbox.de/ios" />

					<p>
						Oder klicken Sie <Link to="/ios">hier.</Link>
					</p>
				</div>
			</div>
		</div>
	);
}

export default Intro;
