import './App.scss';
import Intro from './Intro';
import Ios from './Ios';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function App() {
	return (
		<Router>
			<Switch>
				<Route path="/ios" component={Ios} />
				<Route path="/" component={Intro} />
			</Switch>
		</Router>
	);
}

export default App;
